.banner-home {
	background: url("/assets/home/bg_home.png");
	height: 100vh;
	background-size: cover;
	padding-left: 10vw;

	h1 {
		font-size: 4em;
		color: #089049;
		margin-top: 25vh;
	}

	h5 {
		margin-top: 15vh;
		font-size: 1.5em;
		color: #546D5B;
	}
}


// banner-services

.vans {
	background-image: url(/assets/banner-services/bag.png);

}

.cars {
	background-image: url(/assets/banner-services/cars.webp);
}

.micro-bus {
	background-image: url(/assets/banner-services/micro-bus.webp);
}

.bus {
	background-image: url(/assets/banner-services/bus.webp);
}

.banner-services {
	height: 50vh;
	margin-top: -3.2vh;
}

.service-img {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 50vh;

}

.service-bg-info {
	background-color: #E8E8E8;
}

.service-info {
	background-color: white;
	position: absolute;
	left: 44%;
	margin-top: 1.3vw;
	width: 34vw;
	height: 45vh;
	-webkit-box-shadow: 3px 4px 14px -1px rgba(0, 0, 0, 0.41);
	-moz-box-shadow: 3px 4px 14px -1px rgba(0, 0, 0, 0.41);
	box-shadow: 3px 4px 14px -1px rgba(0, 0, 0, 0.41);
	padding-top: 15vh;

	h3 {
		color: #089049;
		font-size: 2.5em;
	}

	h5 {
		color: gray;
	}

	span {
		color: gray;
		font-weight: bold;
	}
}

.banner-services-reverse {
	height: 50vh;

}

.service-img-reverse {
	background-color: #546D5B;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 50vh;

}

.service-bg-info-reverse {
	background-color: #E8E8E8;
}

.service-info-reverse {
	background-color: white;
	position: absolute;
	left: 22%;
	margin-top: 1.3vw;
	width: 34vw;
	height: 45vh;
	-webkit-box-shadow: 3px 4px 14px -1px rgba(0, 0, 0, 0.41);
	-moz-box-shadow: 3px 4px 14px -1px rgba(0, 0, 0, 0.41);
	box-shadow: 3px 4px 14px -1px rgba(0, 0, 0, 0.41);
	padding-top: 15vh;

	h3 {
		color: #089049;
		font-size: 2.5em;
	}

	h5 {
		color: gray;
	}

	span {
		color: gray;
		font-weight: bold;
	}
}

// itens-services

.itens-services {
	text-align: center;
	padding: 50px;
	background: #fff;
	border-radius: 2px;
	display: inline-block;
	height: 300px;
	margin: 2.5rem;
	position: relative;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

	&:hover {
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	}

	p {
		font-size: 1.3em;
		color: #C4C4C4;
		margin-top: 5vh;
	}

	img {
		transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
		opacity: 0.8;

		&:hover {
			opacity: 0.3;
		}
	}
}

// arrow-down

.scroll-prompt {
	position: absolute;
	z-index: 998;
	bottom: -80px;
	left: 50%;
	margin-left: -80px;
	width: 160px;
	height: 160px;

	.scroll-prompt-arrow-container {
		position: absolute;
		top: 0;
		left: 50%;
		margin-left: -18px;
		animation-name: bounce;
		animation-duration: 1.5s;
		animation-iteration-count: infinite;
	}

	.scroll-prompt-arrow {
		animation-name: opacity;
		animation-duration: 1.5s;
		animation-iteration-count: infinite;
	}

	.scroll-prompt-arrow:last-child {
		animation-direction: reverse;
		margin-top: -6px;
	}

	.scroll-prompt-arrow>div {
		width: 36px;
		height: 36px;
		border-right: 8px solid #089049;
		border-bottom: 8px solid #089049;
		transform: rotate(45deg) translateZ(1px);
	}
}

@keyframes opacity {
	0% {
		opacity: 0;
	}

	10% {
		opacity: 0.1;
	}

	20% {
		opacity: 0.2;
	}

	30% {
		opacity: 0.3;
	}

	40% {
		opacity: 0.4;
	}

	50% {
		opacity: 0.5;
	}

	60% {
		opacity: 0.6;
	}

	70% {
		opacity: 0.7;
	}

	80% {
		opacity: 0.8;
	}

	90% {
		opacity: 0.9;
	}

	100% {
		opacity: 1;
	}
}

@keyframes bounce {
	0% {
		transform: translateY(0);
	}

	10% {
		transform: translateY(3px);
	}

	20% {
		transform: translateY(6px);
	}

	30% {
		transform: translateY(9px);
	}

	40% {
		transform: translateY(12px);
	}

	50% {
		transform: translateY(15px);
	}

	60% {
		transform: translateY(18px);
	}

	70% {
		transform: translateY(21px);
	}

	80% {
		transform: translateY(24px);
	}

	90% {
		transform: translateY(27px);
	}

	100% {
		transform: translateY(30px);
	}
}


@media only screen and (max-width: 768px) {
	.banner-home{
		background-position: center left 445px;
		height: 100vh;
		

		h1{
			text-align: center;
			margin-top:  20vh;
			margin-left: -5vw;
			text-shadow: 2px 2px #080808;
		}

		h5{
			text-align: center;
			margin-left: -5vw;
		}
	}
		
	.scroll-prompt {
		bottom: 50px;
	}


	.service-info {
		width: 80vw;
		left: 10%;
		margin-top: 6vw;
		padding-top: 3.5vh;
		height: 40vh;

	}

	.service-info-reverse {
		width: 80vw;
		left: 10%;
		margin-top: 6vw;
		padding-top: 9vh;

	}

	.service-bg-info {
		display: none;
	}

	.service-bg-info-reverse {
		display: none;
	}

}